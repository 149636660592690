.ChartTooltip {
  .Tooltip {
    width: 0.32rem;
    transform: translateX(-50%);
  }

  &__placeholder {
    opacity: 0;
  }

  &__content {
    color: var(--pal-brand-primary-on);
    font-weight: var(--font-weight-medium);
    line-height: 1.5rem;

    &__title {
      opacity: 0.6;
    }

    &__subtitle {
      opacity: 0.6;
      font-weight: var(--font-weight-light);
      font-size: var(--font-size-small);
    }

    &__row {
      display: flex;
      align-items: center;

      &__circle {
        height: 0.5rem;
        width: 0.5rem;
        border-radius: 50%;
        margin-right: 0.4rem;
      }
    }
  }
}

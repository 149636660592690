.LastLedgersInfo {
  table.Table {
    color: var(--pal-text-tertiary);

    td {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
    }

    .CellIcon {
      display: flex;
      align-items: center;

      svg {
        width: 0.9rem;
        height: 0.9rem;
        margin-right: 0.2rem;
      }
    }
  }

  &__sequence {
    color: var(--pal-text-primary);
    font-weight: var(--font-weight-medium);

    &::before {
      content: "#";
      color: var(--pal-text-tertiary);
    }
  }

  &__chartWrapper {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
  }

  &__pieChart {
    width: 1rem;
    height: 1rem;
  }

  &__barChart {
    width: 0.75rem;
    height: 1rem;
  }
}

.AmountInfoCard {
  flex: 1;
  min-width: 150px;

  .Card {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.4rem;
    height: 9rem;
  }

  &__card_title {
    color: var(--pal-text-tertiary);
    font-weight: var(--font-weight-medium);

    display: flex;
    flex-direction: row;
    align-items: center;

    &__title {
      &:not(:first-child) {
        margin-left: 0.4rem;
      }
      text-transform: uppercase;
    }

    &__icon {
      width: 1rem;
      height: 1rem;

      svg {
        width: 1rem;
        height: 1rem;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 0.4rem;

    h5 {
      font-weight: var(--font-weight-medium);

      &:not(:first-child) {
        margin-left: 0.4rem;
      }
    }

    &__amount_by {
      &::before {
        content: " / ";
        color: var(--pal-text-note);
      }
    }
  }

  &__description {
    color: var(--pal-text-tertiary);
  }
}

body.light-mode {
  --pal-text-note: #999999;

  --pal-graph-primary: #5332e6;
  --pal-graph-secondary: #18dfdf;
  --pal-graph-gradient-primary: #3e1bdb;
  --pal-graph-gradient-secondary: #ffffff;
}

body.dark-mode {
  --pal-text-note: #a9abb2;

  --pal-graph-primary: #6260eb;
  --pal-graph-secondary: #18dfdf;
  --pal-graph-gradient-primary: #3e1bdb;
  --pal-graph-gradient-secondary: #ffffff;
}

#root {
  .Layout__inset {
    font-size: var(--font-size-secondary);
  }

  .Layout__content {
    --layout-window-width-max: 888px;
  }

  table.Table {
    --table-min-width: 360px;

    th {
      color: var(--pal-text-tertiary);
    }
  }
}

.ContentWrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.LedgerClosedTime {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &__icon {
    margin-left: 0.5rem;
  }

  svg {
    display: inline-block;
    width: 1rem;
    height: 1rem;

    &.LedgerClosedTime__icon {
      &--normal {
        stroke: var(--pal-success);
      }

      &--slow {
        stroke: var(--pal-warning);
      }

      &--verySlow {
        stroke: var(--pal-error);
      }
    }
  }
}

.SectionCard__heading,
.LumenSupply {
  .TextLink {
    font-size: var(--font-size-secondary);
    text-transform: uppercase;
    text-align: right;

    .ButtonIcon {
      --ButtonIcon-gap: 0.4rem;

      width: 0.8rem;
      height: 0.8rem;
    }
  }
}

@import "~styles/functions";

.BaseTwoValuesChart {
  li::before {
    content: "";
  }

  &__legend {
    display: flex;
    align-items: baseline;
    color: var(--pal-text-tertiary);
    font-size: var(--font-size-secondary);
    font-weight: var(--font-weight-medium);
    text-transform: uppercase;

    &__item {
      display: flex;
      align-items: baseline;
    }

    &__indicator {
      height: 0.5rem;
      width: 0.5rem;
      border-radius: 50%;
      margin-right: 0.4rem;
    }
  }

  &__y_axis_tick {
    transform: translateY(4px);
  }

  &__max_label {
    @include stroke(1, var(--pal-background-primary));

    fill: var(--pal-text-secondary);
  }
}

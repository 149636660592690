.SectionCard {
  &__heading {
    margin-bottom: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 1.375rem;
    gap: 0.5rem;

    &__title {
      font-size: var(--font-size-secondary);
      text-transform: uppercase;
      font-weight: var(--font-weight-medium);
      color: var(--pal-text-primary);
      display: flex;
      align-items: center;
      gap: 0.3rem;

      svg {
        width: 0.9rem;
        height: 0.9rem;
        stroke: var(--pal-text-primary);
      }
    }

    &__options {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 1rem;
    }
  }
}

.NetworkStatus {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: wrap;

  &__title {
    text-transform: uppercase;
    font-weight: var(--font-weight-medium);
    line-height: 1.375rem;
    color: var(--pal-text-primary);
  }

  &__info {
    line-height: 1.5rem;
    flex-shrink: 0;

    .TextLink {
      font-weight: var(--font-weight-normal);
      color: var(--pal-text-tertiary);

      .ButtonIcon {
        --ButtonIcon-gap: 0.4rem;
        width: 0.8rem;
        height: 0.8rem;
        margin-bottom: 0.16rem;

        svg {
          stroke: var(--pal-text-link);
        }
      }
    }
  }

  &__status {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
    flex-wrap: wrap;
  }
}
